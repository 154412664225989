import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';


import HomePage from "./homepage/HomePage"



ReactDOM.render(

  <HomePage />,
  
  document.getElementById('root')
);

